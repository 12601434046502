import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { useAuth } from "@auth/useAuth"
import { navigate } from "gatsby"
import { Button, Nav, TabContent, TabPane } from "reactstrap"
import Hours from "@components/Tabs/Hours"
import Settings from "./_components/_Settings/_index"
import CoreSettings from "./_components/_CoreSettings/_index"
import Images from "./_components/_Images/_index"
import WsLight from "./_components/_WsLight/_index"

const tabs = [
  {
    name: "Details",
    slug: "details",
  },
  {
    name: "Settings",
    slug: "settings",
  },
  {
    name: "Core Settings",
    slug: "coreSettings",
  },
  {
    name: "Common Images",
    slug: "images",
  },
  {
    name: "Worksimply Light",
    slug: "WsLight",
  },
]

const EditSoPage = ({ location = "" }) => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const [soData, setSo] = useState({})

  useEffect(() => {
    if (typeof window === "undefined" || !isAuthenticated) return
    if (!location.search) {
      navigate("/admin/space-operators")
      return
    }

    fetchSo().then(() => null)
  }, [location, isAuthenticated])

  const fetchSo = async () => {
    const urlParams = new URLSearchParams(location.search)
    const id = urlParams.get("id")
    makeRequest("sp/get-so", { id })
      .then((res) => {
        if (res.so) setSo(res.so)
        if (res.customHolidays) setCustomHolidays(res.customHolidays)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const [customHolidays, setCustomHolidays] = useState([])

  const [activeTab, setActiveTab] = useState(tabs[0].slug)

  return (
    <>
      <SEO title={soData && soData.name ? soData.name : "Space Operator"} />
      <Layout
        title={`Space Operator${
          soData && soData.name ? ` - ${soData.name}` : ""
        }`}
        progress={!soData || !soData.id}
      >
        <Nav pills className="page-content-tabs">
          {tabs.map(({ name, slug }) => {
            return (
              <Button
                key={slug}
                color="default"
                className={`edit-space-tab-nav-link ${
                  activeTab === slug && "active"
                }`}
                onClick={() => setActiveTab(slug)}
              >
                {name}
              </Button>
            )
          })}
        </Nav>
        <div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={tabs[0].slug}>
              <table className="table table-hover table-bordered">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{soData.name}</td>
                  </tr>
                  <tr>
                    <td>Slug</td>
                    <td>
                      <span
                        onClick={() => {
                          if (typeof navigator !== undefined) {
                            navigator.clipboard.writeText(soData.slug)
                          }
                        }}
                      >
                        {soData.slug}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>ID</td>
                    <td>
                      <span
                        onClick={(e) => {
                          if (typeof navigator !== undefined) {
                            navigator.clipboard.writeText(soData.id)
                          }
                        }}
                      >
                        {soData.id}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <hr />
              <br />
              <Hours so_id={soData.id} data={soData} />
            </TabPane>
            <TabPane tabId={tabs[1].slug}>
              <Settings so_id={soData.id} data={soData} />
            </TabPane>
            <TabPane tabId={tabs[2].slug}>
              <CoreSettings so_id={soData.id} data={soData} />
            </TabPane>
            <TabPane tabId={tabs[3].slug}>
              <Images so_id={soData.id} data={soData} />
            </TabPane>
            <TabPane tabId={tabs[4].slug}>
              <WsLight so_id={soData.id} data={soData} refresh={fetchSo} />
            </TabPane>
          </TabContent>
        </div>
      </Layout>
    </>
  )
}

export default EditSoPage
