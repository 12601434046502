import React, { useState } from "react"
import { Button, Col, Row } from "reactstrap"
import { useAuth } from "@auth/useAuth"
import Input from "../../../../../../templates/EditSpace/fields/Input"
import Section from "../../../../../../templates/EditSpace/fields/Section"
import { toast } from "react-toastify"
import get from "lodash/get"
import WsLightImages from "./_Images"

const WsLight = ({ so_id = "", data = {}, refresh = () => null }) => {
  const { makeRequest } = useAuth() || {}

  const config = get(data, "wsLightConfig", {}) || {}

  const [progress, setProgress] = useState(false)

  const [team_size_range, set_team_size_range] = useState(
    config.team_size_range || ""
  )

  const [cities, set_cities] = useState(config.cities || "")

  const [theme_color, set_theme_color] = useState(
    config.theme_color || "#000000"
  )

  const [secondary_theme_color, set_secondary_theme_color] = useState(
    config.secondary_theme_color || "#0a0a0a"
  )

  const [map_marker_color, set_map_marker_color] = useState(
    config.map_marker_color || "#ffffff"
  )

  const [theme_font_color, set_theme_font_color] = useState(
    config.theme_font_color || "#000000"
  )

  const [font_family, set_font_family] = useState(config.font_family || "")

  const [header_bg_color, set_header_bg_color] = useState(
    config.header_bg_color || "#ffffff"
  )

  const saveData = (e) => {
    if (e) {
      e.preventDefault()
    }
    setProgress(true)
    makeRequest("so/update-ws-light-config", {
      id: so_id,
      config_id: config.id || null,
      data: {
        space_operator_id: so_id,
        team_size_range,
        cities,
        theme_color,
        secondary_theme_color,
        theme_font_color,
        header_bg_color,
        font_family,
      },
    })
      .then(function (res) {
        refresh()
        setProgress(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setProgress(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <div>
      {config.id ? (
        <>
          <WsLightImages data={data} />
          <hr />
        </>
      ) : (
        ""
      )}

      <Section title={`Details`} first>
        <Row>
          <Col lg="6">
            <Input
              type="text"
              label={"Team Sizes"}
              helpText={"CVS, Ex: 1,2,3,4,5,6,7-10,11-15"}
              value={team_size_range}
              onChange={(value) => set_team_size_range(value)}
            />
            <Input
              type="text"
              label={"Cities"}
              helpText={
                "Values separated by a comma without spaces near commas. Ex: Toronto,San Francisco,Kitchener"
              }
              value={cities}
              onChange={(value) => set_cities(value)}
            />
            <Input
              type="text"
              label={"Google Font Name"}
              helpText={
                "Only google fonts are supported. If the font is invalid, the website will display the default font of the browser."
              }
              value={font_family}
              onChange={(value) => set_font_family(value)}
            />
            <Input
              type="color"
              label={"Theme Color"}
              helpText={"Hex color: #ecd295, default: black"}
              value={theme_color || "#000000"}
              onChange={(value) => set_theme_color(value)}
            />
            <Input
              type="color"
              label={"Secondary Color"}
              helpText={
                "Secondary color for buttons. Hex color: #ecd295, default: #0a0a0a"
              }
              value={secondary_theme_color || "#0a0a0a"}
              onChange={(value) => set_secondary_theme_color(value)}
            />
            <Input
              type="color"
              label={"Theme Font Color"}
              helpText={
                "Color of the fonts when background is theme color. Hex color: #ecd295, defaults: black"
              }
              value={theme_font_color || "#000000"}
              onChange={(value) => set_theme_font_color(value)}
            />
            <Input
              type="color"
              label={"Header BG Color"}
              helpText={
                "Color for the BG stripe. Hex color: #ecd295, default: white"
              }
              value={header_bg_color || "#ffffff"}
              onChange={(value) => set_header_bg_color(value)}
            />
            <Input
              type="color"
              label={"Map Marker's color"}
              helpText={
                "Color of the marker. Hex color: #ecd295, default: Theme Color"
              }
              value={map_marker_color || theme_color || "#ffffff"}
              onChange={(value) => set_map_marker_color(value)}
            />
          </Col>
          <Col md="12">
            <Button color={"success"} onClick={saveData} disabled={progress}>
              Save
            </Button>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default WsLight
