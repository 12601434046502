import React from "react"
import { useAuth } from "@auth/useAuth"
import Section from "../../../../../../templates/EditSpace/fields/Section"
import { Col, Row } from "reactstrap"
import SingleImage from "../../../../../../components/Inputs/SingleImageUpload"

const Images = ({ data = {} }) => {
  const { makeRequest } = useAuth() || {}

  const replaceImage = async (table, id, column, value) => {
    await makeRequest("replace-image", {
      table,
      id,
      column,
      value,
    })
      .then((res) => console.log(res))
      .catch((e) => {
        console.log("failed to replace the image")
      })
  }

  return (
    <div>
      <Section title={`Common Images`} first>
        <Row>
          <Col lg="12" className={"mb-4"}>
            <SingleImage
              existingImage={data.email_logo}
              label="Logo for Emails"
              subtitle={
                "Only PNG files - 300 x 160px. If updated, must invalidate cloudfront manually!"
              }
              awsPathname={`space-operators/${data.id}`}
              handleUpload={(url) =>
                replaceImage("space_operators", data.id, "email_logo", url)
              }
              fileName={`email-logo.png`}
            />
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default Images
