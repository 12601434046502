import React from "react"

import { Col, FormGroup, Input, Label, Row } from "reactstrap"

const TextInput = ({
  label,
  type,
  placeholder = "",
  name,
  className = "",
  disabled,
  onChange = () => null,
  onBlur = () => null,
  value,
  children,
  error = false,
  col = 12,
  helpText = "",
}) => {
  return (
    <FormGroup className={className}>
      <Row>
        <Col md={col}>
          {label && (
            <Label for={name} dangerouslySetInnerHTML={{ __html: label }} />
          )}
          {helpText && (
            <span
              className={"d-block mb-1"}
              dangerouslySetInnerHTML={{ __html: helpText }}
            />
          )}
          <Input
            type={type}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            value={value || ""}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value)}
          >
            {children}
          </Input>
          {error && <p className="text-danger mt-1">{error}</p>}
        </Col>
      </Row>
    </FormGroup>
  )
}

export default TextInput
