import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import { useAuth } from "@auth/useAuth"
import Input from "../../../../../../templates/EditSpace/fields/Input"
import Section from "../../../../../../templates/EditSpace/fields/Section"
import RadioBox from "../../../../../../templates/EditSpace/fields/RadioSwitch"
import { toast } from "react-toastify"
import MeetingRooms from "./_MeetingRooms"

const Settings = ({ so_id = "", data = {} }) => {
  const { makeRequest } = useAuth() || {}

  const [progress, setProgress] = useState(false)
  const [triggerSaveReady, setTriggerSaveReady] = useState(false)

  const [monthly_offices_enabled, set_monthly_offices_enabled] = useState(
    data.monthly_offices_enabled || false
  )

  const [website_url, set_website_url] = useState(data.website_url || "")

  const [dashboard_type, set_dashboard_type] = useState(
    data.dashboard_type || ""
  )

  const [on_demand_offices_daily_enabled, set_on_demand_offices_daily_enabled] =
    useState(data.on_demand_offices_daily_enabled || false)

  const [
    on_demand_offices_hourly_enabled,
    set_on_demand_offices_hourly_enabled,
  ] = useState(data.on_demand_offices_hourly_enabled || false)

  const [day_passes_enabled, set_day_passes_enabled] = useState(
    data.day_passes_enabled || false
  )

  const [custom_dashboard_enabled, set_custom_dashboard_enabled] = useState(
    data.custom_dashboard_enabled || false
  )

  useEffect(() => {
    if (!triggerSaveReady) return
    saveData()
    setTriggerSaveReady(false)
  }, [triggerSaveReady])

  const saveData = (e) => {
    if (e) {
      e.preventDefault()
    }
    setProgress(true)
    makeRequest("so/edit", {
      id: so_id,
      data: {
        monthly_offices_enabled,
        on_demand_offices_daily_enabled,
        on_demand_offices_hourly_enabled,
        day_passes_enabled,
        custom_dashboard_enabled,
        website_url,
        dashboard_type,
      },
    })
      .then(function (res) {
        setProgress(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setProgress(false)
        toast.error("Failed to save the details")
      })
  }

  const [legal_business_name, set_legal_business_name] = useState(
    data.legal_business_name || ""
  )

  const [address, set_address] = useState(data.address || "")

  const [cancellation_deadline_in_hours, set_cancellation_deadline_in_hours] =
    useState(data.cancellation_deadline_in_hours || "0")

  const saveBusinessData = (e) => {
    if (e) {
      e.preventDefault()
    }
    setProgress(true)
    makeRequest("so/edit", {
      id: so_id,
      data: {
        legal_business_name,
        address,
        website_url,
        cancellation_deadline_in_hours: Number(
          cancellation_deadline_in_hours || 48
        ),
      },
    })
      .then(function (res) {
        setProgress(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setProgress(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <div>
      <Section title={`Business Details`} first>
        <Row>
          <Col lg="6">
            <Input
              type="text"
              label={"Legal Business Name"}
              value={legal_business_name}
              onChange={(value) => set_legal_business_name(value)}
            />
            <Input
              type="text"
              label={"Address"}
              value={address}
              onChange={(value) => set_address(value)}
            />
            <Input
              type="text"
              label={"Website URL"}
              value={website_url}
              onChange={(value) => set_website_url(value)}
            />
            <Input
              type="select"
              label={"Cancellation Deadline for On-demand Workspace"}
              value={cancellation_deadline_in_hours}
              onChange={(value) => set_cancellation_deadline_in_hours(value)}
            >
              <option value="0">None</option>
              <option value="24">24 Hours</option>
              <option value="48">48 Hours</option>
            </Input>
          </Col>
          <Col md="12">
            <Button
              color={"success"}
              onClick={saveBusinessData}
              disabled={progress || !legal_business_name}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Section>

      <Section title={"Configuration"}>
        <Row>
          <Col lg="6">
            <RadioBox
              label="Monthly Workspace"
              onChange={(value) => {
                set_monthly_offices_enabled(value)
                setTriggerSaveReady(true)
              }}
              name="monthly"
              value={monthly_offices_enabled}
              disabled={progress}
            />

            <hr />

            <RadioBox
              label="On-demand Offices - Daily"
              onChange={(value) => {
                set_on_demand_offices_daily_enabled(value)
                setTriggerSaveReady(true)
              }}
              name="onDemandDaily"
              value={on_demand_offices_daily_enabled}
              disabled={progress}
            />
            <RadioBox
              label="On-demand Offices - Hourly"
              onChange={(value) => {
                set_on_demand_offices_hourly_enabled(value)
                setTriggerSaveReady(true)
              }}
              name="onDemandHourly"
              value={on_demand_offices_hourly_enabled}
              disabled={progress}
            />
            <RadioBox
              label="Day Passes"
              onChange={(value) => {
                set_day_passes_enabled(value)
                setTriggerSaveReady(true)
              }}
              name="dayPasses"
              value={day_passes_enabled}
              disabled={progress}
            />

            <hr />

            <MeetingRooms so_id={so_id} data={data} />

            <hr />
            <Input
              type="select"
              label={"Dashboard Type"}
              value={dashboard_type}
              onChange={(value) => set_dashboard_type(value)}
            >
              <option value="">None</option>
              <option value="prospect_analysis">Prospect Analysis</option>
              <option value="inventory_analytics">Inventory Analytics</option>
            </Input>
            <Button color={"success"} onClick={saveData} disabled={progress}>
              Save
            </Button>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default Settings
