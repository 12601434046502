import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import { useAuth } from "@auth/useAuth"
import Input from "../../../../../../templates/EditSpace/fields/Input"
import Section from "../../../../../../templates/EditSpace/fields/Section"
import { toast } from "react-toastify"
import axios from "axios"

const CoreSettings = ({ so_id = "", data = {} }) => {
  const { makeRequest } = useAuth() || {}

  const [progress, setProgress] = useState(false)
  const [versionForBuildStatus, setVfbs] = useState(
    Math.random().toString(36).substr(2, 5)
  )
  const [triggerSaveReady, setTriggerSaveReady] = useState(false)

  const [build_hook_for_website, setBhfw] = useState(
    data.build_hook_for_website || ""
  )

  const [build_status_icon_for_website, setBsifw] = useState(
    data.build_status_icon_for_website || ""
  )

  const [domain, set_domain] = useState(data.domain || "")

  const [dashboard_url, set_dashboard_url] = useState(data.dashboard_url || "")

  const [profile_generator_url, set_profile_generator_url] = useState(
    data.profile_generator_url || ""
  )

  useEffect(() => {
    if (!triggerSaveReady) return
    saveData()
    setTriggerSaveReady(false)
  }, [triggerSaveReady])

  const saveData = (e) => {
    if (e) {
      e.preventDefault()
    }
    setProgress(true)
    makeRequest("so/edit", {
      id: so_id,
      data: {
        build_hook_for_website,
        build_status_icon_for_website,
        dashboard_url,
        profile_generator_url,
        domain,
      },
    })
      .then(function (res) {
        setProgress(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setProgress(false)
        toast.error("Failed to save the details")
      })
  }

  const build = () => {
    if (!build_hook_for_website) return
    axios
      .post(build_hook_for_website)
      .then(() => {
        toast.success("Build started!")
        setVfbs(Math.random().toString(36).substr(2, 5))
      })
      .catch(() => {
        toast.error("Failed to start the build. Please contact administrator.")
        setVfbs(Math.random().toString(36).substr(2, 5))
      })
  }

  return (
    <div>
      <Section title={`Details`} first>
        <Row>
          <Col lg="6">
            <Input
              type="text"
              label={"Domain"}
              value={domain}
              onChange={(value) => set_domain(value)}
            />
            <Input
              type="text"
              label={"Dashboard URL"}
              value={dashboard_url}
              onChange={(value) => set_dashboard_url(value)}
            />
            <Input
              type="text"
              label={"Build hook for the website"}
              value={build_hook_for_website}
              onChange={(value) => setBhfw(value)}
            />
            <Input
              type="text"
              label={"URL for the Profile Generator"}
              value={profile_generator_url}
              onChange={(value) => set_profile_generator_url(value)}
            />
            <Input
              type="text"
              label={"Build status icon for the website"}
              value={build_status_icon_for_website}
              onChange={(value) => setBsifw(value)}
            />
            {build_status_icon_for_website && (
              <img
                src={`${build_status_icon_for_website}?version=${versionForBuildStatus}`}
                alt="Build status"
                className={"mb-3 d-inline-block"}
              />
            )}
          </Col>
          <Col md="12">
            <Button color={"success"} onClick={saveData} disabled={progress}>
              Save
            </Button>
            {build_hook_for_website && (
              <Button
                color={"secondary"}
                outline
                className={"ml-2"}
                onClick={build}
              >
                Deploy Website
              </Button>
            )}
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default CoreSettings
