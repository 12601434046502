import React from "react"
import { useAuth } from "@auth/useAuth"
import Section from "../../../../../../templates/EditSpace/fields/Section"
import { Col, Row } from "reactstrap"
import SingleImage from "../../../../../../components/Inputs/SingleImageUpload"
import get from "lodash/get"

const WsLightImages = ({ data = {} }) => {
  const { makeRequest } = useAuth() || {}

  const config = get(data, "wsLightConfig", {}) || {}

  const replaceImage = async (column, value) => {
    if (!config || !config.id) {
      return
    }
    await makeRequest("replace-image", {
      table: "client_config_ws_light",
      id: config.id,
      column,
      value,
    }).catch((e) => {
      console.log("failed to replace the image")
    })
  }

  if (!config || !config.id) {
    return null
  }

  return (
    <div>
      <Section title={`Images`} first>
        <Row>
          <Col lg="12" className={"mb-4"}>
            <SingleImage
              existingImage={config.logo_for_dark_bg}
              label="Logo for dark BG"
              subtitle={"Only SVG/PNG files - 150x80 px"}
              handleUpload={(url) => replaceImage("logo_for_dark_bg", url)}
              awsPathname={`space-operators/${data.id}`}
              darkBg
            />
            <br />
            <SingleImage
              existingImage={config.logo_for_light_bg}
              label="Logo for light BG"
              subtitle={"Only SVG/PNG files - 150x80 px"}
              handleUpload={(url) => replaceImage("logo_for_light_bg", url)}
              awsPathname={`space-operators/${data.id}`}
            />
            <br />
            <SingleImage
              existingImage={config.results_page_bg_desktop}
              label="Results page BG image - Desktop"
              subtitle={"Only JPG files - 3360x600 px"}
              handleUpload={(url) =>
                replaceImage("results_page_bg_desktop", url)
              }
              awsPathname={`space-operators/${data.id}`}
            />
            <br />
            <SingleImage
              existingImage={config.results_page_bg_tablet}
              label="Results page BG image - Tablet"
              subtitle={"Only JPG files - 2400x600 px"}
              handleUpload={(url) =>
                replaceImage("results_page_bg_tablet", url)
              }
              awsPathname={`space-operators/${data.id}`}
            />
            <br />
            <SingleImage
              existingImage={config.results_page_bg_mobile}
              label="Results page BG image - Mobile"
              subtitle={"Only JPG files - 750x600 px"}
              handleUpload={(url) =>
                replaceImage("results_page_bg_mobile", url)
              }
              awsPathname={`space-operators/${data.id}`}
            />
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default WsLightImages
