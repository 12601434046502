import React, { useContext, useState } from "react"
import Section from "../../../templates/EditSpace/fields/Section"
import Input from "../../../templates/EditSpace/fields/Input"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"
import get from "lodash/get"
import AdditionalIncludes from "../../../templates/EditSpace/fields/AdditionalIncludes"
import isEmail from "validator/es/lib/isEmail"
import userHasAccess from "../../../utils/userHasAccess"
import { SpaceContext } from "@hooks/SpaceContext"

const Hours = ({ so_id, data = {} }) => {
  const { makeRequest } = useAuth()

  const {
    data: { soData },
  } = useContext(SpaceContext)

  const [opening_time, set_opening_time] = useState(data.opening_time || "")

  const [closing_time, set_closing_time] = useState(data.closing_time || "")

  const inquiry_email_recipients = get(data, "inquiry_email_recipients", "")

  const [generic_email, set_generic_email] = useState(data.generic_email || "")

  const [inquiry_email_recipients_array, set_inquiry_email_recipients_array] =
    useState(
      inquiry_email_recipients ? inquiry_email_recipients.split(",") : []
    )

  const [inProgress, setInProgress] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    setInProgress(true)
    makeRequest("so/edit", {
      id: so_id,
      data: {
        opening_time,
        closing_time,
        generic_email,
        inquiry_email_recipients: inquiry_email_recipients_array
          ? inquiry_email_recipients_array.join(",")
          : "",
      },
    })
      .then(function (res) {
        setInProgress(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setInProgress(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <React.Fragment>
      <Section title={"Operating Hours"} first>
        <Row>
          <Col sm="12" className={"d-flex align-items-center"}>
            <label className={"mb-3 mr-2"}>Monday - Friday</label>
            <Input
              type="select"
              name="officeTermCommitment"
              placeholder="# of months"
              value={opening_time}
              onChange={set_opening_time}
            >
              <option value="" disabled>
                Please select
              </option>
              <option>7:00 AM</option>
              <option>7:30 AM</option>
              <option>8:00 AM</option>
              <option>8:30 AM</option>
              <option>9:00 AM</option>
              <option>9:30 AM</option>
              <option>10:00 AM</option>
              <option>10:30 AM</option>
              <option>11:00 AM</option>
              <option>11:30 AM</option>
              <option>12:00 PM</option>
              <option>12:30 PM</option>
              <option>01:00 PM</option>
              <option>01:30 PM</option>
              <option>02:00 PM</option>
              <option>02:30 PM</option>
              <option>03:00 PM</option>
            </Input>
            <p className={"mb-3 mx-2"}>–</p>
            <Input
              type="select"
              name="dedicatedDesksTermCommitment"
              placeholder="# of months"
              value={closing_time}
              onChange={set_closing_time}
            >
              <option value="" disabled>
                Please select
              </option>
              <option>12:00 PM</option>
              <option>12:30 PM</option>
              <option>1:00 PM</option>
              <option>1:30 PM</option>
              <option>2:00 PM</option>
              <option>2:30 PM</option>
              <option>3:00 PM</option>
              <option>3:30 PM</option>
              <option>4:00 PM</option>
              <option>4:30 PM</option>
              <option>5:00 PM</option>
              <option>5:30 PM</option>
              <option>6:00 PM</option>
              <option>6:30 PM</option>
              <option>7:00 PM</option>
              <option>7:30 PM</option>
            </Input>
          </Col>
        </Row>
      </Section>
      {userHasAccess(soData, "monthly_offices_enabled") && (
        <Section title={"Inquiry Email Recipients"} first>
          <Row>
            <Col lg="6">
              <label>Email Address</label>
              <p>Multiple emails can be added.</p>
              <AdditionalIncludes
                placeholder={"Email"}
                values={inquiry_email_recipients_array}
                isValidated={(value) => value && isEmail(value)}
                handleChange={set_inquiry_email_recipients_array}
              />
            </Col>
          </Row>
        </Section>
      )}
      <Section title={"Generic email address"} first>
        <Row>
          <Col lg="6">
            <Input
              type="text"
              label="Email Address"
              name="generic-email-address"
              placeholder="E-mail"
              value={generic_email}
              onChange={set_generic_email}
              helpText={"This is used to send important email notifications."}
            />
          </Col>
        </Row>
      </Section>

      <Row>
        <Col lg="12">
          <Button
            color="success"
            size="lg"
            type="submit"
            className="btn-square mt-4"
            disabled={inProgress}
            onClick={onSubmit}
          >
            Save
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Hours
