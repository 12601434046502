import React, { useState } from "react"
import { Col, Label, Progress, Row } from "reactstrap"
import Dropzone from "react-dropzone"
import { useAuth } from "../../../auth/useAuth"
import getOptimizedImageUrl from "../../../utils/getOptimizedImageUrl"
import { v4 as uuid } from "uuid"

import * as s from "./index.module.less"

const SingleImage = ({
  existingImage = null,
  accept = null,
  circle = false,
  handleUpload = () => null,
  awsPathname = "",
  subtitle = "",
  label = "",
  darkBg = false,
  fileName = "",
}) => {
  const { makeRequest } = useAuth()
  // const [files, setFiles] = useState([]);
  const [image, setImage] = useState(existingImage || false)
  const [progress, setProgress] = useState(false)

  const onDrop = async (files) => {
    if (files.length === 0) {
      return
    }
    setProgress(true)
    const file = files[0]

    const reader = new FileReader()

    reader.onload = async (e) => {
      const fileNameArr = file.name.split(".")
      const ext = fileNameArr[fileNameArr.length - 1]

      const response = await makeRequest("upload/single-image/get-signed-url", {
        key: `${awsPathname}/${fileName || `${uuid()}.${ext}`}`,
        type: file.type,
      })

      let binary = atob(e.target.result.split(",")[1])
      let array = []
      // for each letter in the encrypted version, get binary value and push it to array
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      // use the array to construct a binary object (blob)
      let blobData = new Blob([new Uint8Array(array)], {
        type: file.type,
      })

      await fetch(response.uploadURL, {
        method: "PUT",
        body: blobData,
        mode: "cors",
      })

      // replace image
      await handleUpload(response.Url)

      setProgress(false)

      setImage(response.Url)
    }

    reader.readAsDataURL(file)
  }

  return (
    <>
      <Row className="row-eq-height">
        <Col xs={"7"} md={"8"} lg={"9"} className={"d-flex align-items-center"}>
          <div>
            <Label for="">{label}</Label>
            {subtitle && (
              <span>
                <br />
                {subtitle}
              </span>
            )}
          </div>
        </Col>
        <Col>
          <div className={s.drop_zone}>
            {image && (
              <img
                src={fileName ? image : getOptimizedImageUrl(image, 300)}
                alt=""
                className={`img-thumbnail uploader-single-image-preview ${
                  circle && "circle-uploaded-image"
                } ${darkBg ? s.dark : ""}`}
              />
            )}
            <div className="dropzone-wrapper dropzone-wrapper-sm">
              <Dropzone
                disabled={progress}
                multiple={false}
                accept={accept}
                // to pass the category with the image
                onDrop={(files) => onDrop(files)}
                // onFileDialogCancel={this.onCancel.bind(this)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className={`dropzone-custom-wrapper ${circle && "circle"}`}
                  >
                    <input {...getInputProps()} />
                    <div className={`dropzone-content ${circle && "circle"}`}>
                      {progress ? (
                        <Progress animated bar value="100" />
                      ) : (
                        <p className="mb-0">{image ? "Change" : "Add"}</p>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SingleImage
