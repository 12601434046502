import React, { useState } from "react"
import { Button } from "reactstrap"
import { useAuth } from "@auth/useAuth"
import Input from "../../../../../../templates/EditSpace/fields/Input"
import RadioBox from "../../../../../../templates/EditSpace/fields/RadioSwitch"
import { toast } from "react-toastify"
import get from "lodash/get"

const MeetingRooms = ({ so_id = "", data = {} }) => {
  const { makeRequest } = useAuth() || {}

  const config = get(data, "meeting_rooms_config", {}) || {}

  const [progress, setProgress] = useState(false)

  const [meeting_rooms_enabled, set_meeting_rooms_enabled] = useState(
    data?.meeting_rooms_enabled || false
  )

  const [meeting_rooms_integration_type, set_meeting_rooms_integration_type] =
    useState(config.type || "")

  const [client_id, set_client_id] = useState(config.client_id || "")
  const [client_secret, set_client_secret] = useState(
    config.client_secret || ""
  )

  const [stripe_connected_account_id, set_stripe_connected_account_id] =
    useState(config.stripe_connected_account_id || "")

  const [org_slug, set_org_slug] = useState(config.orgSlug || "")

  const saveData = (e) => {
    if (e) {
      e.preventDefault()
    }
    setProgress(true)

    makeRequest("so/update-meeting-rooms-integration", {
      id: so_id,
      data: {
        meeting_rooms_enabled,
        meeting_rooms_integration_type,
        client_id,
        client_secret,
        org_slug,
        stripe_connected_account_id,
      },
    })
      .then(function (res) {
        setProgress(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setProgress(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <>
      <RadioBox
        label="Meeting Rooms"
        onChange={(value) => {
          set_meeting_rooms_enabled(value)
        }}
        name="coffee"
        value={meeting_rooms_enabled}
        disabled={progress}
      />

      {meeting_rooms_enabled && (
        <div className={"pl-4"}>
          <Input
            type="select"
            label={"Integration Type"}
            value={meeting_rooms_integration_type}
            onChange={(value) => set_meeting_rooms_integration_type(value)}
          >
            <option value="">None</option>
            <option value="office_rnd">Office R&D</option>
            <option value="request_to_book">Request to Book</option>
          </Input>

          {meeting_rooms_integration_type === "office_rnd" && (
            <div className={"pl-4"}>
              <Input
                type="text"
                label={"Org Slug of client's Office R&D Account"}
                value={org_slug}
                onChange={(value) => set_org_slug(value)}
              />

              <Input
                type="text"
                label={"Client ID of client's Office R&D Account"}
                value={client_id}
                onChange={(value) => set_client_id(value)}
              />

              <Input
                type="text"
                label={"Client Secret client's Office R&D Account"}
                value={client_secret}
                onChange={(value) => set_client_secret(value)}
              />

              <Input
                type="text"
                label={"Stripe Connected Account ID"}
                value={stripe_connected_account_id}
                onChange={(value) => set_stripe_connected_account_id(value)}
              />
            </div>
          )}
          <Button color={"success"} onClick={saveData} disabled={progress}>
            Save
          </Button>
        </div>
      )}
    </>
  )
}

export default MeetingRooms
